@import './src/scss/variables';
@import './src/scss/app';

textarea {
  resize: none;
}

.single-column {
  .markdown__editor, .markdown__viewer {
    width: 100%;
    float: none;
    padding-right: 0;
  }
}

.markdown__viewer ul, .markdown__viewer ol {
  list-style-type: disc !important;
  margin-left: 1.5rem !important;
}

.markdown__editor, .markdown__viewer {
  width: 50%;
  padding-bottom: 0;
}

.markdown__editor > div:first-of-type,
.markdown__viewer > div:first-of-type {
  border: 1px solid #858995;
  color: inherit;
  padding: 1.5em;
  padding-top: 1em;
  display: block;
  width: 100%;
  height: 500px;
  background: #fff;
  border-radius: $border-radius;
  text-align: left;
}

.markdown__editor {
  display: inline-block;
  padding-right: 1rem;
}

.markdown__viewer {
  float: right;
}

.markdown__viewer div {
  overflow: auto;
}

.markdown__editor-button,
.markdown__viewer-button {
    width: 50%;
    padding-bottom: 0;
}

.markdown__editor-button {
  display: inline-block;
  padding-right: 1rem;
}

.markdown__viewer-button {
	float: right;
}

.single-column {
  .markdown__editor-button, .markdown__viewer-button {
    width: 100%;
    float: none;
    padding-right: 0;
  }
}

@media screen and (max-width: 900px) {
  .markdown__editor, .markdown__viewer {
    float: none;
    padding-top: 2em;
    width: 100%;
  }

  .markdown__editor > textarea, .markdown__viewer > div {
    height: 90%;
  }
}

#markdown-result {
  height: 1px;
  overflow: hidden;
  border: none;
  color: white;
}